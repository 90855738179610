<template>
	<div>
		<section class="page-board board-show" @tap="closePopper">
			<div class="board-con">
				<canvas id="myCanvas" ref="canvas" v-finger:touch-start="touchStart" v-finger:tap="tap" v-finger:press-move="pressMove"
					class="board-canvas" />
			</div>
			<section class="board-actions">
				<ul style="margin: 0;">
					<li v-for="item in shapesArr" :key="item.type" class="btn-action"
						:class="tool === item.type ? 'btn-active': ''">
						<div class="btn-content" @click="selectShape(item)">
							<span :class="item.class" />
						</div>
					</li>
					<li class="btn-action" :class="tool === 'brush' ? 'btn-active' : ''">
						<section v-if="popper.brush" class="picker-wrapper picker-slider">
							<el-slider v-model="brush.lineWidth" vertical :show-tooltip="false" height="120px" :min="4"
								:max="12" @change="linePick" />
							<div class="line-wrapper">
								<span class="line-con" :style="brushStyle" />
							</div>
						</section>
						<div class="picker-btn" @click="selectBrush">
							<span style="color:blue;" v-if="tool === 'brush'">{{$store.state.teachlanguagedata.pen}}</span>
							<span style="color:#fff;" v-else>{{$store.state.teachlanguagedata.pen}}</span>
						</div>
					</li>
					<li class="btn-action" :class="tool === 'eraser' ? 'btn-active' : ''" @click="selectEraser">
						<div class="btn-content">
							<span style="color:blue;"
								v-if="tool === 'eraser'">{{$store.state.teachlanguagedata.eraser}}</span>
							<span style="color:#fff;" v-else>{{$store.state.teachlanguagedata.eraser}}</span>
						</div>
					</li>
					<li class="btn-action">
						<section v-if="popper.color" class="picker-wrapper picker-color">
							<div v-for="item in colorArr" :key="item.color" class="picker-item" @click="colorPick(item)">
								<span :style="{backgroundColor: item.color}" class="picker-con"
									:class="item.active ? 'picker-active': ''">
									<i class="el-icon-check" />
								</span>
							</div>
						</section>
						<div class="btn-content" @click="selectColor">
							<span class="btn-color" :style="{backgroundColor: brush.color}" />
						</div>
					</li>
					<li class="btn-action">
							<div style="color:#fff" @click="getDotSocall()">{{$store.state.teachlanguagedata.AllowRoll}}</div>
						</li>
						<li class="btn-action">
							<div style="color:#fff" @click="regetWebSocket()">{{$store.state.teachlanguagedata.ReWriteData}}</div>
						</li>
					<!-- <li class="btn-action" @click="clearAll">
						<div>
							<svg-icon icon-class="clear" />
						</div>
					</li> -->
					
				</ul>
				<div class="btn-close" @click="closeBoard">{{$store.state.teachlanguagedata.close}}</div>
			</section>
			<canvas-mask v-show="isMask" ref="mask" :config="{ color: brush.color, tool: tool, radius: eraser.radius}"
				@draw="drawShape" @close="closePopper" />
		</section>
		<GetDot ref="GetDot"></GetDot>
	</div>
</template>
<script>
	import CanvasMask from './canvasMask.vue'
	import GetDot from '../ComponentPage/GetDot.vue'
	export default {
		components: {
			CanvasMask,
			GetDot
		},
		data() {
			return {
				touchStartshow: false,
				context: null,
				visible: false,
				disabled: false,
				tools: [{
						icon: 'brush',
						active: true,
						type: 'pen',
						expand: true
					},
					{
						icon: 'eraser',
						active: false,
						type: 'eraser',
						expand: true
					}
				],
				shapesArr: [{
						class: 'icon-rect',
						active: false,
						type: 'rect'
					},
					{
						class: 'icon-line',
						active: true,
						type: 'line'
					},
					{
						class: 'icon-circle',
						active: false,
						type: 'circle'
					}
				],
				shape: 'line',
				popper: {
					line: false,
					shape: false,
					color: false
				},
				brush: {
					lineWidth: 4,
					color: '#DE350B',
					linePopper: false
				},
				position: {
					x: 0,
					y: 0
				},
				tool: 'brush',
				eraserZo: 3,
				eraser: {
					radius: 30
				},
				colorArr: [{
						color: '#071737',
						active: false
					},
					{
						color: '#00875A',
						active: false
					},
					{
						color: '#DE350B',
						active: true
					},
					{
						color: '#FFAB00',
						active: false
					}
				],
				isShape: false,
				isMask: false
			}
		},
		watch: {
			'brush.lineWidth'(val) {
				let data = {
					statues: 'lineWidth',
					zhi: val
				}
				this.$refs.GetDot.xiugai(data)
			},
			'brush.color'(val) {
				let data = {
					statues: 'color',
					zhi: val
				}
				this.$refs.GetDot.xiugai(data)
			}
		},
		computed: {
			brushStyle() {
				return `width: ${this.brush.lineWidth + 4}px; height: ${this.brush.lineWidth + 4}px;`
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			getDotSocall() {
				this.$refs.GetDot.isopenzhi('1')
			},
			regetWebSocket() {
				let cs = document.getElementById("myCanvas");
				let cxt = cs.getContext("2d");
				cxt.clearRect(0, 0, this.$refs.canvas.width,this.$refs.canvas.height);
				this.$refs.GetDot.getWebSocket()

			},
			closeBoard() {
				this.selectBrush()
				this.closePopper()
				this.$emit('close')
				this.$refs.GetDot.getWebSocketcolse()
				this.isMask = false
			},
			init() {
				this.$refs.canvas.height = window.innerHeight
				this.$refs.canvas.width = window.innerWidth
				this.context = this.$refs.canvas.getContext('2d')
				this.selectBrush()
				this.closePopper()
			},
			tap(evt) {
				const {
					x,
					y
				} = this.position
				switch (this.tool) {
					case 'brush':
						this.drawPoints(x + 2, y + 2)
						break
					default:
						break
				}
			},
			touchStart(evt) {
				this.closePopper()
				const {
					clientX,
					clientY
				} = evt.touches[0]
				this.position.x = clientX
				this.position.y = clientY
				switch (this.tool) {
					case 'eraser':
						this.clearPoints(clientX, clientY)
						break
					default:
						break
				}
			},
			pressMove(evt) {
				const touches = evt.touches
				const {
					clientX,
					clientY
				} = touches[0]
				switch (this.tool) {
					case 'eraser':
						this.clearPoints(clientX, clientY)
						break
					case 'brush':
						this.drawPoints(clientX, clientY)
						break
					default:
						this.position.x = clientX
						this.position.y = clientY
				}
				evt.preventDefault()
			},
			drawPoints(x, y) {
				this.context.beginPath()
				this.context.moveTo(this.position.x, this.position.y)
				this.context.lineTo(x, y)
				this.context.lineWidth = this.brush.lineWidth
				this.context.lineCap = 'round'
				this.context.lineJoin = 'round'
				this.context.strokeStyle = this.brush.color
				this.context.stroke()
				this.position = {
					x: x,
					y: y
				}
			},
			clearPoints(x, y) {
				const radius = this.eraser.radius
				let step = 1
				while (step <= radius) {
					const pox = Math.abs(x + step - radius)
					const width = (radius - step) * 2
					const height = Math.sqrt(Math.pow(radius, 2) - Math.pow(radius - step, 2)) * 2
					const poy = y - height / 2
					this.context.clearRect(pox, poy, width, height)
					step++
				}
			},
			clearAll() {
				this.context.clearRect(0, 0, window.innerWidth, window.innerHeight)
			},
			drawShape(start, end) {
				switch (this.tool) {
					case 'line':
						this.context.beginPath()
						this.context.strokeStyle = this.brush.color
						this.drawLine(start, end)
						break
					case 'rect':
						this.context.beginPath()
						this.context.strokeStyle = this.brush.color
						this.drawRect(start, end)
						break
					case 'circle':
						this.context.beginPath()
						this.context.strokeStyle = this.brush.color
						this.drawArc(start, end)
						break
					case 'eraser':
						this.clearPoints(start.x, start.y)
						break
				}
			},
			drawLine(start, end) {
				this.context.lineWidth = this.brush.lineWidth
				this.context.moveTo(start.x, start.y)
				this.context.lineTo(end.x, end.y)
				this.context.stroke()
			},
			drawRect(start, end) {
				const width = Math.ceil(Math.abs(start.x - end.x))
				const height = Math.ceil(Math.abs(start.y - end.y))
				if (width * height) {
					this.context.beginPath()
					this.context.lineWidth = this.brush.lineWidth
					this.context.rect(Math.min(start.x, end.x), Math.min(start.y, end.y), width, height)
					this.context.stroke()
				}
			},
			drawArc(start, end) {
				const x = Math.min(start.x, end.x) + Math.abs(start.x - end.x) / 2
				const y = Math.min(start.y, end.y) + Math.abs(start.y - end.y) / 2
				const r = Math.sqrt(Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2), 2) / 2
				if (r) {
					this.context.lineWidth = this.brush.width
					this.context.arc(x, y, r, 0, 2 * Math.PI)
					this.context.stroke()
				}
			},
			colorPick(item) {
				if (item.active) return
				this.colorArr.forEach(item => {
					item.active = false
				})
				item.active = true
				this.brush.color = item.color
				this.popper.color = false
			},
			shapePick(item) {
				this.shapesArr.forEach(el => {
					el.active = false
				})
				item.active = true
				this.tool = item.type
				this.shape = item.type
				this.popper.shape = false
			},
			linePick(value) {
				this.brush.lineWidth = value
				this.$refs.mask.lineWidth = value
			},
			selectEraser(type) {
				this.closePopper()
				this.isShape = false
				this.tool = 'eraser'
				this.isMask = true
			},
			selectBrush() {
				this.$nextTick(()=>{
					const bool = this.popper.brush
					this.isShape = false
					this.closePopper()
					if (this.tool === 'brush') {
						this.popper.brush = !bool
					} else {
						this.tool = 'brush'
					}
					this.isMask = false
				})
			},
			selectShape(item) {
				this.tool = item.type
				this.isMask = true
				this.shapesArr.forEach(item => {
					item.active = false
				})
				item.active = true
			},
			selectColor() {
				this.closePopper()
				this.popper.color = true
			},
			closePopper() {
				this.popper = {
					brush: false,
					color: false,
					shape: false
				}
			}
		}
	}
</script>
<style scoped lang="scss">
	.page-board {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2;

		.board-con {
			width: 100%;
			height: 100%;
		}

		.board-actions {
			position: absolute;
			bottom: 40px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 20;
			background-color: rgba(9, 30, 66, 0.7);
			border-radius: 4px;
			display: flex;
			align-items: center;

			.slider-action {
				width: 100px;
			}

			.picker-wrapper {
				position: absolute;
				bottom: 50px;
				left: 50%;
				transform: translateX(-50%);
				background: #fff;
				border-radius: 4px;
				box-shadow: 0px 2px 4px 0px #C2CDDE;
				padding: 10px;
				border: 1px solid #deffec;
			}

			.picker-slider {
				padding: 20px 10px 10px;

				.line-wrapper {
					padding: 10px 0 0;
					width: 40px;
					height: 35px;
					text-align: center;
				}

				.line-con {
					margin-top: 10px;
					border-radius: 50%;
					display: inline-block;
					background-color: #000;
				}
			}

			.picker-shape {
				.shape-item {
					height: 32px;
					text-align: center;
					padding: 8px 0;
					box-sizing: content-box;
				}

				.icon-rect {
					border: 3px solid #FFF;
					border-radius: 2px;
					width: 30px;
					height: 30px;
					display: inline-block;
				}

				.icon-circle {
					border: 3px solid #fff;
					border-radius: 50%;
					width: 30px;
					height: 30px;
					display: inline-block;
				}

				.icon-line {
					width: 32px;
					border: 2px solid #fff;
					height: 0;
					border-radius: 4px;
					display: inline-block;
					background-color: #fff;
					transform: translateY(5px) rotateZ(45deg);
				}
			}

			.picker-color {
				min-height: 180px;

				.picker-item {
					padding: 5px;
				}
			}

			.picker-con {
				display: inline-block;
				width: 28px;
				height: 28px;
				text-align: center;

				.el-icon-check {
					color: #fff;
					line-height: 28px;
					font-size: 24px;
					display: none;
				}
			}

			.picker-active {
				.el-icon-check {
					display: inline;
				}
			}

			.shape-active {
				.picker-con {
					border-color: #18409e;
				}

				.icon-line {
					background-color: #18409E;
				}
			}
		}

		.btn-action {
			margin: 10px;
			vertical-align: middle;
			display: inline-block;
			position: relative;

			.icon-rect {
				border: 3px solid #fff;
				border-radius: 2px;
				width: 24px;
				height: 24px;
				display: inline-block;
			}

			.icon-circle {
				border: 3px solid #fff;
				border-radius: 50%;
				width: 24px;
				height: 24px;
				display: inline-block;
			}

			.icon-line {
				width: 28px;
				border: 2px solid #fff;
				height: 0;
				border-radius: 4px;
				display: inline-block;
				transform: rotateZ(45deg);
			}

			.svg-icon {
				width: 30px;
				height: 30px;
			}
		}

		.board-canvas {
			width: 100%;
			height: 100%;
		}

		.btn-content {
			border-radius: 2px;
			min-height: 24px;

			.btn-color {
				border-radius: 2px;
				display: inline-block;
				width: 24px;
				height: 24px;
				margin-top: 3px;
			}

			.icon-rect,
			.icon-circle {
				margin: 3px;
			}
		}

		.btn-active {
			color: #19409e;

			.picker-btn {
				border-radius: 2px;
			}

			.icon-rect,
			.icon-line,
			.icon-circle {
				border-color: #4CAAFF;
				border-radius: 2px;
			}

			.icon-line {
				background-color: #4CAAFF;
				margin: 0;
			}

			.icon-circle {
				border-radius: 50%;
			}

			.btn-content {
				border-radius: 2px;
				// background: rgba(9, 30, 66, 0.2);
			}
		}

		.btn-close {
			float: right;
			font-size: 14px;
			line-height: 36px;
			height: auto;
			text-align: center;
			padding: 8px 10px;
			color: #fff;
			border-radius: 0 2px 2px 0;
		}

		.show-action {
			padding: 10px;
			background-color: #999;
		}
	}
</style>
