<template>
  <div class="board-mask">
    <canvas
      ref="mask"
      v-finger:touch-start="touchStart"
      v-finger:touch-move="touchMove"
      v-finger:touch-end="touchEnd"
      class="canvas"
    />
  </div>
</template>
<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      down: {
        x: 0,
        y: 0
      },
      up: {
        x: 0,
        y: 0
      },
      context: null,
      lineWidth: 4
    }
  },
  mounted() {
    this.$refs.mask.height = window.innerHeight
    this.$refs.mask.width = window.innerWidth
    this.context = this.$refs.mask.getContext('2d')
  },
  methods: {
    touchStart(evt) {
      this.$emit('close')
      const touches = evt.touches[0]
      switch (this.config.tool) {
        case 'eraser':
          this.context.beginPath()
          this.clearPoints(touches.clientX, touches.clientY)
          break
        default:
          this.down.x = Math.ceil(touches.clientX)
          this.down.y = Math.ceil(touches.clientY)
          this.up.x = this.down.x
          this.up.y = this.down.y
          break
      }
    },
    touchMove(evt) {
      const { clientY, clientX } = evt.touches[0]
      this.clearDrawer(clientX, clientY)
      this.context.beginPath()
      this.context.strokeStyle = this.config.color
      this.context.lineWidth = this.lineWidth
      switch (this.config.tool) {
        case 'eraser':
          this.clearPoints(clientX, clientY)
          break
        case 'rect':
          this.drawRect(clientX, clientY)
          break
        case 'circle':
          this.drawArc(clientX, clientY)
          break
        case 'line':
          this.drawLine(clientX, clientY)
          break
        default:
          this.up.x = clientX
          this.up.y = clientY
      }
      evt.preventDefault()
    },
    touchEnd(evt) {
      this.$emit('draw', this.down, this.up)
      this.context.clearRect(0, 0, window.innerWidth, window.innerHeight)
    },
    clearPoints(x, y) {
      const r = this.config.radius
      this.context.lineWidth = 4
      this.context.strokeStyle = 'rgb(218,76,15,0.2)'
      this.context.arc(x, y, r, 0, 2 * Math.PI)
      this.context.stroke()
      this.$emit('draw', { x, y })
    },
    drawLine(x, y) {
      this.context.lineCap = 'round'
      this.context.moveTo(this.down.x, this.down.y)
      this.context.lineTo(x, y)
      this.context.stroke()
    },
    drawRect(x, y) {
      const width = Math.ceil(Math.abs(this.down.x - x))
      const height = Math.ceil(Math.abs(this.down.y - y))
      this.context.beginPath()
      this.context.lineWidth = this.lineWidth
      this.context.rect(Math.min(this.down.x, x), Math.min(this.down.y, y), width, height)
      this.context.stroke()
    },
    clearDrawer(x, y) {
      const width = this.$refs.mask.width
      const height = this.$refs.mask.height
      this.context.clearRect(0, 0, width, height)
      this.up.x = x
      this.up.y = y
    },
    drawArc(x, y) {
      const start = {
        x: Math.min(this.down.x, x) + Math.abs(this.down.x - x) / 2,
        y: Math.min(this.down.y, y) + Math.abs(this.down.y - y) / 2
      }
      const r = Math.sqrt(Math.pow(this.down.x - x, 2) + Math.pow(this.down.y - y, 2), 2) / 2
      this.context.arc(start.x, start.y, r, 0, 2 * Math.PI)
      this.context.stroke()
    }
  }
}
</script>
<style lang="scss" scoped>
  .board-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 15
  }
  .canvas {
    width: 100%;
    height: 100%;
  }
</style>
