<template>
	<div style="position: absolute;top: 0px;left: 0;right: 0;" :class="[isopen == 1?'active':'active1']">
		<div style="max-width: 100%;overflow: auto;max-height: 740px;">
			<canvas id="myCanvas" @click="touchStart" style="transform: rotate(90deg);" :width="enlargeWidth" :height="enlargeWidth"></canvas>
		</div>
	</div>
</template>

<script>
	import {
		WebSocketFunc
	} from '../../utils/src_utils_socket.js'
	import {
		queryMacList
	} from '../../api/base.js'
	export default {
		data() {
			return {
				isopen:2,
				playtime:10,
				penMac: '',
				enlargeWidth: 124,
				enlargeHeight: 175,
				Magnification:2,
				cxt: '',
				params: '',
				timer2: '',
				brush: {
					lineWidth: 4,
					color: '#DE350B',
				},
				data_result:[],
			};
		},
		components: {
		},
		mounted() {
			this.Magnification=(window.innerWidth)/this.enlargeWidth-3
			this.enlargeWidth = window.innerWidth-200
			this.enlargeHeight = this.enlargeWidth/297*210
			
			// this.Magnification=(window.innerWidth)/this.enlargeWidth
			// this.enlargeHeight = window.innerWidth
			// this.enlargeWidth = this.enlargeHeight/297*210
			
			// this.Magnification=(window.innerWidth)/this.enlargeWidth
			// this.enlargeWidth = window.innerWidth
			// this.enlargeHeight = this.enlargeWidth/210*297
			this.init_canvas()
		},
		methods: {
			xiugai(data){
				this.brush[data.statues]=data.zhi
			},
			touchStart(e){
				this.isopenzhi('2')
				this.$parent.touchStart(e)
			},
			// 改变isopen值
			isopenzhi(canhsu){
				this.isopen=canhsu
			},
			getTeachPem(){
				queryMacList({"userId":JSON.parse(localStorage.getItem("teachuserID"))}).then(res=>{
					if(res.data.result[0]){
						this.penMac=res.data.result[0]
						this.getWebSocket()
					}else{
						console.log("老师未绑笔")
					}
				})
			},
			dots_draw(data, n) {
				this.cxt.beginPath();
				this.cxt.lineWidth =  this.brush.lineWidth
				this.cxt.strokeStyle = this.brush.color
				data.x.forEach((datasitemitem, datasitemindex) => {
						if (datasitemindex == 0) {
							this.cxt.moveTo(datasitemitem * n, data.y[datasitemindex] * n);
						} else {
							this.cxt.lineTo(datasitemitem * n, data.y[datasitemindex] * n);
						}
				})
				this.cxt.stroke();
			},
			init_canvas: function(img_src) {
				var c = document.getElementById("myCanvas");
				this.cxt = c.getContext("2d");
				this.cxt.fillStyle = "#FFf";
				this.cxt.fillRect(0, 0, this.enlargeWidth, this.enlargeWidth);
				this.getTeachPem()
			},
			getWebSocketcolse() {
				clearInterval(this.timer2)
				if (this.ws) {
					this.ws.close()
				}
			},
			getWebSocket() {
				this.getWebSocketcolse()
				if (this.ws) {
					this.ws.close()
				}
				let params = "KS0012"+this.penMac
				const url = 'wss://websocket.youcun.tech/wss/webSocket-link/kafkaSocket'
				let cs = document.getElementById("myCanvas");
				let cxt = cs.getContext("2d");
				cxt.clearRect(0, 0, this.enlargeWidth, this.enlargeWidth);
				WebSocketFunc(this.ws, params, url, (data) => {
					data = JSON.parse(data)
					if (data.flag != '9') {
						data.result=JSON.parse(data.result)
						this.data_result=data.result
						this.dots_draw(data.result, this.Magnification)
					}
				}, (ws) => {
					this.ws = ws
				}, (timer) => {
					this.timer2 = timer
				})
			},
		}
	}
</script>
<style>
	.active{
		z-index:3;
	}
	.active1{
		z-index:1;
	}
	.pc{
		font-size: 24px;
		margin-left: 40px;
	}
	.disflal{
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
	}
	.disflal>span{
		white-space: nowrap;
		margin-top: 15px;
		margin-right: 30px;
	}
	.disflal>div{
		width: 220px;
	}
</style>
